import { useState, useEffect } from 'react';
import { callAction } from '../utils'; // Ensure you import your callAction function

export const useCommerceProductById = (productId, props) => {
    const [isLoadingProduct, setIsLoadingProduct] = useState(true);
    const [product, setProduct] = useState(null);
    const [description, setDescription] = useState('');

    const fetchProductById = async () => {
        try {
            const productResponse = await callAction(
                props,
                'commerce-rest-get',
                `products?searchCriteria[filter_groups][0][filters][0][field]=entity_id&searchCriteria[filter_groups][0][filters][0][value]=${productId}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`
            );
            const fetchedProduct = productResponse.items?.[0] || null;

            if (fetchedProduct) {
                setProduct(fetchedProduct);

                // Find the description in the custom_attributes
                const descriptionAttr = fetchedProduct.custom_attributes.find(attr => attr.attribute_code === 'description');
                if (descriptionAttr) {
                    setDescription(descriptionAttr.value);
                }
            } else {
                setProduct(null);
            }
        } catch (error) {
            console.error('Error fetching product by ID:', error);
            setProduct(null);
        }
    };

    useEffect(() => {
        if (productId) {
            fetchProductById().then(() => setIsLoadingProduct(false));
        }
    }, [productId]);

    return { isLoadingProduct, product, description };
};
