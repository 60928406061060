import axios from 'axios';

export const GeneratePromotionText = async (productName, productDescription, token) => {
    const prompt = `I want to promote our product ${productName} which has the following description: ${productDescription}. Can you please help me to generate promotional text that we can use, for example, in a mailing list?`;

    try {
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-4o-mini',
                messages: [
                    { role: 'system', content: 'You are a helpful assistant.' },
                    { role: 'user', content: prompt }
                ]
            },
            {
                headers: {
                    'Authorization': `Bearer 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`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data.choices[0].message.content;
    } catch (error) {
        console.error('Error generating promotional text:', error);
        throw new Error('Could not generate promotional text');
    }
};
