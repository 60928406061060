import React, { useState, useEffect } from 'react';
import { Flex, Heading, TextField, Button, View, TextArea } from '@adobe/react-spectrum';
import { attach } from '@adobe/uix-guest';
import { extensionId } from '../Constants';
import { useCommerceProductById } from '../../hooks/useCommerceProductById'; // Import the hook
import { GeneratePromotionText } from '../GeneratePromotionText'; // Import the function

export const PromoteProducts = (props) => {
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [promotionText, setPromotionText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false); // Loading state for AI generation
    const token = '<your-api-token>'; // Replace this with your actual token

    useEffect(() => {
        const getGuestConnection = async () => {
            const guestConnection = await attach({ id: extensionId });
            const selectedIds = await guestConnection.sharedContext.get('selectedIds');
            setSelectedProductId(selectedIds[0]); // Assume one product selected, and using ID
        };
        getGuestConnection();
    }, []);

    const { product, isLoadingProduct, description } = useCommerceProductById(selectedProductId, props); // Use the hook

    const handlePromotion = () => {
        console.log('Promoting product:', product);
        console.log('Promotion text:', promotionText);
    };

    const handleGeneratePromotionText = async () => {
        if (product && description) {
            setIsGenerating(true);
            try {
                const generatedText = await GeneratePromotionText(product.name, description, token);
                setPromotionText(generatedText); // Set the generated promotion text
            } catch (error) {
                console.error(error);
            } finally {
                setIsGenerating(false);
            }
        }
    };

    return (
        <View>
            <Heading level={1}>Promote Product</Heading>
            {isLoadingProduct ? (
                <Heading level={2}>Loading product details...</Heading>
            ) : product ? (
                <Flex direction="column" gap="size-200">
                    <TextField
                        label="Product Name"
                        value={product.name}
                        isReadOnly
                    />
                    <TextArea
                        label="Product Description"
                        value={description}
                        isReadOnly
                        width="size-3600"
                    />
                    <TextArea
                        label="Promotion Text"
                        value={promotionText}
                        onChange={setPromotionText}
                        placeholder="Enter or generate promotion text..."
                        width="size-3600"
                        height="size-3600"
                    />
                    <Flex direction="row" gap="size-200">
                        <Button
                            variant="cta"
                            onPress={handlePromotion}
                        >
                            Promote Product
                        </Button>
                        <Button
                            variant="primary"
                            onPress={handleGeneratePromotionText}
                            isDisabled={isGenerating} // Disable the button while loading
                        >
                            {isGenerating ? 'Generating...' : 'Generate Promotion Text'}
                        </Button>
                    </Flex>
                </Flex>
            ) : (
                <Heading level={2}>No product found</Heading>
            )}
        </View>
    );
};
