import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // This assumes you are using react-router for navigation

export const DeleteOrder = () => {
    const [orderId, setOrderId] = useState(null);
    const [deleteStatus, setDeleteStatus] = useState('');
    const navigate = useNavigate(); // For redirecting after deletion

    useEffect(() => {
        const urlString = window.location.href;
        const queryString = urlString.split('?')[1];
        const searchParams = new URLSearchParams(queryString);
        const orderId = searchParams.get('orderId');

        if (orderId) {
            deleteOrder(orderId);
        }
    }, []);
    const deleteOrder = async (orderId) => {
        fetch(`https://magento-backend.sokoleyplatform.site/rest/V1/orders/${orderId}/delete`, {
                method: 'GET',
                headers: {Authorization: `Bearer h3cgfmslftxct3p9my13aqp9fm3ed763`}
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setDeleteStatus("Order deleted.");
        })
        .catch(error => {
            setDeleteStatus("Failed to delete the order.");
        });
    };

    return (
        <div>
            {deleteStatus ? <p>{deleteStatus}</p> : <p>Deleting order...</p>}
        </div>
    );
};

export default DeleteOrder;
