/*
Copyright 2024 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/
import React, { useEffect, useRef } from 'react';
import {
    Content,
    Heading,
    IllustratedMessage,
    TableView,
    TableHeader,
    TableBody,
    Column,
    Row,
    Cell,
    View,
    Flex,
    ProgressCircle,
    Grid
} from '@adobe/react-spectrum'
import { Chart } from 'chart.js/auto';
import { useCommerceOrders } from '../hooks/useCommerceOrders'

export const Orders = (props) => {
    const { isLoadingCommerceOrders, commerceOrders } = useCommerceOrders(props)

    useEffect(() => {
        // Log the commerceOrders data to the console
        console.log(commerceOrders);
    }, [commerceOrders]);

    const ordersColumns = [
        { name: 'Order Id', uid: 'increment_id' },
        { name: 'Status', uid: 'status' },
        { name: 'Store Name', uid: 'store_name' },
        { name: 'Total Item Count', uid: 'total_item_count' },
        { name: 'Total Quantity', uid: 'total_qty_ordered' },
        { name: 'Total Due', uid: 'total_due' },
        { name: 'Tax', uid: 'tax_amount' },
        { name: 'Created At', uid: 'created_at' },
        { name: 'Customer Locale', uid: 'billing_address.country_id' },
        { name: 'Payment Method', uid: 'payment.method' }
    ]

    const priceChartRef = useRef(null);
    const localeChartRef = useRef(null);
    const paymentChartRef = useRef(null);
    const skuChartRef = useRef(null);
    const priceChartInstance = useRef(null);
    const localeChartInstance = useRef(null);
    const paymentChartInstance = useRef(null);
    const skuChartInstance = useRef(null);

    useEffect(() => {
        if (!isLoadingCommerceOrders && commerceOrders.length > 0) {
            // Prepare data for the charts
            const priceData = commerceOrders.map(order => order.base_total_due);
            const localeData = commerceOrders.reduce((acc, order) => {
                acc[order.billing_address.country_id] = (acc[order.billing_address.country_id] || 0) + 1;
                return acc;
            }, {});
            const paymentData = commerceOrders.reduce((acc, order) => {
                acc[order.payment.method] = (acc[order.payment.method] || 0) + 1;
                return acc;
            }, {});
            const skuData = commerceOrders.flatMap(order => order.items.map(item => item.sku));
            const skuCounts = skuData.reduce((acc, sku) => {
                acc[sku] = (acc[sku] || 0) + 1;
                return acc;
            }, {});

            // Create or update price chart
            if (priceChartInstance.current) {
                priceChartInstance.current.data.labels = commerceOrders.map(order => order.increment_id);
                priceChartInstance.current.data.datasets[0].data = priceData;
                priceChartInstance.current.update();
            } else {
                priceChartInstance.current = new Chart(priceChartRef.current, {
                    type: 'bar',
                    data: {
                        labels: commerceOrders.map(order => order.increment_id),
                        datasets: [{
                            label: 'Total Due',
                            data: priceData,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
                });
            }

            // Create or update locale chart
            if (localeChartInstance.current) {
                localeChartInstance.current.data.labels = Object.keys(localeData);
                localeChartInstance.current.data.datasets[0].data = Object.values(localeData);
                localeChartInstance.current.update();
            } else {
                localeChartInstance.current = new Chart(localeChartRef.current, {
                    type: 'pie',
                    data: {
                        labels: Object.keys(localeData),
                        datasets: [{
                            data: Object.values(localeData),
                            backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(75, 192, 192, 0.2)'],
                            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
                            borderWidth: 1
                        }]
                    }
                });
            }

            // Create or update payment chart
            if (paymentChartInstance.current) {
                paymentChartInstance.current.data.labels = Object.keys(paymentData);
                paymentChartInstance.current.data.datasets[0].data = Object.values(paymentData);
                paymentChartInstance.current.update();
            } else {
                paymentChartInstance.current = new Chart(paymentChartRef.current, {
                    type: 'doughnut',
                    data: {
                        labels: Object.keys(paymentData),
                        datasets: [{
                            data: Object.values(paymentData),
                            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
                            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
                            borderWidth: 1
                        }]
                    }
                });
            }

            // Create or update SKU chart
            if (skuChartInstance.current) {
                skuChartInstance.current.data.labels = Object.keys(skuCounts);
                skuChartInstance.current.data.datasets[0].data = Object.values(skuCounts);
                skuChartInstance.current.update();
            } else {
                skuChartInstance.current = new Chart(skuChartRef.current, {
                    type: 'bar',
                    data: {
                        labels: Object.keys(skuCounts),
                        datasets: [{
                            label: 'SKU Count',
                            data: Object.values(skuCounts),
                            backgroundColor: 'rgba(153, 102, 255, 0.2)',
                            borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
                });
            }
        }
    }, [isLoadingCommerceOrders, commerceOrders]);

    function renderEmptyState() {
        return (
            <IllustratedMessage>
                <Content>No data available</Content>
            </IllustratedMessage>
        )
    }

    return (
        <View>
            {isLoadingCommerceOrders ? (
                <Flex alignItems="center" justifyContent="center" height="100vh">
                    <ProgressCircle size="L" aria-label="Loading…" isIndeterminate />
                </Flex>
            ) : (
                <View margin={10}>
                    <Heading level={1}>Fetched orders from Adobe Commerce</Heading>
                    <Grid
                        areas={['mainArea data']}
                        columns={['2fr', '1fr']}
                        rows={['auto']}
                        gap="size-200"
                    >
                        <View gridArea="mainArea">
                            <View>
                                <TableView
                                    flex
                                    overflowMode="wrap"
                                    aria-label="orders table"
                                    renderEmptyState={renderEmptyState}
                                    minHeight="static-size-1000"
                                >
                                    <TableHeader columns={ordersColumns}>
                                        {column => <Column key={column.uid}>{column.name}</Column>}
                                    </TableHeader>
                                    <TableBody items={commerceOrders}>
                                        {order => (
                                            <Row key={order['increment_id']}>{columnKey => <Cell>{order[columnKey]}</Cell>}</Row>
                                        )}
                                    </TableBody>
                                </TableView>
                            </View>
                            <View marginTop="size-200">
                                <Heading level={2}>Charts</Heading>
                                <Flex direction="row" gap="size-200" wrap>
                                    <View>
                                        <canvas ref={priceChartRef} />
                                    </View>
                                    <View>
                                        <canvas ref={localeChartRef} />
                                    </View>
                                    <View>
                                        <canvas ref={paymentChartRef} />
                                    </View>
                                    <View>
                                        <canvas ref={skuChartRef} />
                                    </View>
                                </Flex>
                            </View>
                        </View>
                        <View gridArea="data">
                            <Heading level={2}>Raw Data</Heading>
                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                                {JSON.stringify(commerceOrders, null, 2)}
                            </pre>
                        </View>
                    </Grid>
                </View>
            )}
        </View>
    )
}
